import { useMutation } from '@apollo/client';
import { Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import { NoteInput } from 'sr-types/lib/note/v1/graphql';
import { KeyValue, Production, Resource } from 'sr-types/lib/production/v1/graphql';
import { Shortlist } from 'sr-types/lib/shortlist/v1/graphql';
import { UserContext } from '../common/auth/UserContext';
import { EMPTY_DATERANGE } from '../lab/form/LabForms';
import { getStringFromDateRange } from '../production/helpers/activityUtils';
import { client, deleteLineItemFromShortlist } from './shortlist';
import { DeleteLineItemFromShortlistType } from './shortlistApi';
import { LineItemExtended } from './ShortlistLineItems';
import { InquiryStatus, InquiryStatusEnum } from './useShortlistLineItemsColDefs';

export interface ShortlistLineItemsProps {
    shortlistId: string;
    viewState: Shortlist;
    setViewState: Dispatch<SetStateAction<Shortlist>>;
    resourceData?: Resource;
    productionData?: Production;
    refetchShortlistData?: () => void;
    resourceNote?: NoteInput;
}

export type LineItemAdditionalFields = {
    inquiryStatus?: InquiryStatus | string;
    isPerson?: boolean;
    isOrganization?: boolean;
    id?: string | number;
    availability?: InquiryStatus | string;
};

export const defineShortlistLineItemRows = (viewState: Shortlist, alternateProposeText: string | ReactElement) => {
    return viewState?.lineItems
        ? viewState?.lineItems?.map((shortlistLineItem: LineItemExtended, index) => {
              const inquiryStatus = shortlistLineItem?.inquiry?.inquiryStatus;
              const proposedDate = shortlistLineItem?.inquiry?.proposedDate;
              const alternateProposed = `${inquiryStatus} (${!proposedDate?.end ? alternateProposeText + ' ' : ''}${getStringFromDateRange(proposedDate)})`;

              const availability = inquiryStatus
                  ? inquiryStatus === InquiryStatusEnum.alternateProposed && proposedDate
                      ? alternateProposed
                      : inquiryStatus
                  : InquiryStatusEnum.empty;

              return {
                  id: index,
                  ...shortlistLineItem,
                  isPerson: shortlistLineItem.itemReference?.type === 'Person',
                  isOrganization: shortlistLineItem.itemReference?.type === 'Organization',
                  inquiryStatus: shortlistLineItem?.inquiry?.inquiryStatus || InquiryStatusEnum.empty,
                  availability,
                  __reorder__: shortlistLineItem.name
              };
          })
        : [];
};

export const defineCommonInquiryProductionData = (
    resourceData: Resource,
    productionData: Production,
    productionOptions
) => {
    return {
        resource: { label: resourceData.name, ...resourceData?.reference },
        resourceCategory: resourceData.resourceCategory,
        locations: resourceData.locations,
        dateRange: resourceData.resourceSchedule.dateRange || { ...EMPTY_DATERANGE.dateRange },
        production: {
            reference: productionData.reference,
            name: productionData.name,
            headline: '',
            productionType: productionData.productionType
        },
        productionOptions: [
            ...productionOptions,
            ...(productionData
                ? [
                      {
                          id: productionData.name,
                          label: productionData.name
                      }
                  ]
                : [])
        ],
        productionSummary: productionData.about || ''
    };
};

export const productionOtherTitlesToOptions = (otherTitles: Array<KeyValue>) => {
    return (
        otherTitles
            ?.filter((item) => item.value)
            .map((title) => {
                return { id: title?.value, label: title?.value, key: title?.key };
            }) || []
    );
};

import { ApolloProvider, useMutation } from '@apollo/client';
import { SearchkitClient, SearchkitProvider } from '@searchkit/client';
import { parseISO } from 'date-fns';
import { findIndex, fromPairs, get } from 'lodash';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Production, Reference } from 'sr-types/lib/search/v1/graphql';
import Attachments from '../common/attachments/Attachments';
import { ALL_PERMISSIONS, AllPermissions, getRowPermissions } from '../common/auth/api';
import { useFeaturePermissions, UserContext } from '../common/auth/UserContext';
import WithSidePanel from '../common/components/layout/WithSidePanel';
import I18n, { useI18n } from '../common/i18n/I18n';
import { Icons } from '../common/icons/Icons';
import { V } from '../common/Layout';
import DataGrid from '../common/list/DataGrid';
import ListView from '../common/list/ListView';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import { PLANNING, PROFILES, SUPPLIER } from '../common/nav/apps';
import Notes from '../common/notes/Notes';
import { toYearFromISOString } from '../common/utils/dateTime';
import useHistory from '../common/utils/useHistory';
import { deleteProductionResourcesFromSchedulerProductionData } from './../scheduler/scheduler';
import CreateProductionForm from './form/CreateProductionForm';
import { client, deleteQuery } from './helpers/production';
import { useDeleteAttachments, useGetAttachments, useSaveAttachment } from './helpers/productionApi';
import useProductionGridColumns from './helpers/useProductionGridColumns';
import useProductionPreFilters from './helpers/useProductionPreFilters';
import ProductionImport from './ProductionImport';
import { isSchedulerProductionRefetchNeededAtom } from './schedulerTimeline/ProductionSchedulerRefetchAtom';
import { SchedulerProvider } from '../scheduler/SchedulerContext';
import DataList from '../common/list/DataList';
import ProductionHeader from './header/ProductionHeader';
import { useStyling } from '../common/Theme';
import { alpha } from '@mui/material';
import { EnvironmentPermissionContext } from '../common/auth/EnvironmentPermissionContext';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams } from '@mui/x-data-grid-premium';
import { useOnMobile } from '../common/hooks/breakpoints';

export enum ProductionModes {
    production = 'production',
    workspace = 'workspace'
}
export type EditorPropTypes = {
    editingId: string;
    setEditingId: React.Dispatch<React.SetStateAction<string>>;
};

const Editor = ({ editingId, setEditingId }: EditorPropTypes) => {
    return (
        <CreateProductionForm
            title={<I18n token={'production.form.edit.title'} />}
            isOpen={!!editingId}
            productionId={editingId}
            onCancel={() => {
                setEditingId(undefined);
            }}
            onSave={() => {
                setEditingId(undefined);
            }}
        />
    );
};

const List = ({ id, data, feature, refetch, setListLoading, setIsEditorOpen, ...rest }) => {
    const { application } = useContext(UserContext);
    const { changeRoute } = useHistory();
    const { isDarkMode } = useStyling();
    const featurePermissions = useFeaturePermissions(feature);
    const [notesEntityReference, setNotesEntityReference] = useState<Reference>(undefined);
    const [productionName, setProductionName] = useState<string>(undefined);
    const [editingId, setEditingId] = useState<string>();
    const [attachmentEntityReference, setAttachmentEntityReference] = useState<Reference>();
    const { attachmentSave } = useSaveAttachment();
    const [sidePanelOpened, setSidePanelOpened] = useState<string>();

    const panels = [
        {
            id: 'notes',
            icon: Icons.Notes,
            label: <I18n token="notes.title" />,

            component: (onClose) => (
                <Notes
                    id={'notes-production'}
                    title={
                        <I18n
                            token="notes.panel.title"
                            values={{
                                entityName: productionName
                            }}
                        />
                    }
                    onClose={() => {
                        setNotesEntityReference(undefined);
                        setProductionName(undefined);
                        onClose();
                    }}
                    featurePermissions={featurePermissions}
                    entityPermissions={ALL_PERMISSIONS}
                    entityReference={notesEntityReference}
                />
            )
        },
        {
            id: 'attachments',
            icon: Icons.Attachments,
            label: <I18n token="attachments.title" />,
            tooltip: attachmentEntityReference ? (
                <I18n token="atttachments.close" />
            ) : (
                <I18n token="attachment.from.grid" />
            ),
            disabled: !attachmentEntityReference,
            component: (onClose) => (
                <Attachments
                    id={'attachments-lab'}
                    entityReference={attachmentEntityReference}
                    title={
                        <I18n
                            token="attachments.panel.title"
                            values={{ entityName: attachmentEntityReference?.label }}
                        />
                    }
                    onClose={() => {
                        setAttachmentEntityReference(undefined);
                        onClose();
                    }}
                    onSave={attachmentSave}
                    useGetAttachments={useGetAttachments}
                    useDeleteAttachments={useDeleteAttachments}
                />
            )
        }
    ];

    return (
        <WithSidePanel
            panels={panels}
            leftToolbar={undefined}
            visibleSidePanelId={
                notesEntityReference ? 'notes' : attachmentEntityReference ? sidePanelOpened : undefined
            }
            hideHeader
        >
            <DataList
                id={id}
                feature={feature}
                items={data?.results?.hits?.items}
                onClick={(item) => {
                    changeRoute(`${application.home}/workspace/${item.id}/details`);
                }}
                {...rest}
                ItemRenderer={(props) => <ProductionHeader viewState={props.item} isReadOnly={true} />}
                pagination={data?.results.hits.page}
                onEdit={(id: string) => {
                    setEditingId(id);
                }}
                onNotes={(ref, name) => {
                    setNotesEntityReference(ref);
                    setProductionName(name);
                }}
                onAttachments={(row) => {
                    setAttachmentEntityReference(row);
                    setSidePanelOpened('attachments');
                }}
                listItemSx={{
                    border: 1,
                    borderColor: isDarkMode ? alpha('#FFF', 0.23) : alpha('#000', 0.23),
                    borderRadius: 1,
                    mb: 2
                }}
                disableRipple
            />
            {!!editingId && <Editor editingId={editingId} setEditingId={setEditingId} />}
        </WithSidePanel>
    );
};

const Table = ({ entity, feature, data: raw, uri, history, ...props }) => {
    const { application } = useContext(UserContext);
    const { changeRoute, searchParams } = useHistory();
    const isMobile = useOnMobile();
    const attachmentLabel = useI18n('attachments.title');
    const notesLabel = useI18n('notes.title');

    const productionMode = searchParams.get('productionMode');
    const isProductionMode = useMemo(() => {
        return productionMode === ProductionModes.production || application.home === SUPPLIER.home;
    }, [productionMode]);

    const featurePermissions = useFeaturePermissions('Production');

    const [editingId, setEditingId] = useState<string>();
    const [entityReference, setEntityReference] = useState<Reference>();
    const [rowEntityName, setRowEntityName] = useState<string>();
    const [sidePanelOpened, setSidePanelOpened] = useState<string>();

    const defaultColumns = useProductionGridColumns(isProductionMode);

    const handleSidePanelOpening = (row: Production) => {
        setEntityReference(row.reference);
        setRowEntityName(`${row['name']}`);
    };

    const columnVisibility = fromPairs(
        defaultColumns.filter((column) => get(column, 'hide') === true).map((key) => [key.field, false])
    );

    const { attachmentSave } = useSaveAttachment();

    const rows = React.useMemo(
        () =>
            raw?.results?.hits?.items.map((entity, index) => {
                const otherTitlesValue = entity.otherTitles
                    ? entity.otherTitles.find((title) => title.key === 'AKA')?.value
                    : '';
                const entityReleaseDate = entity.releaseDate != '' ? toYearFromISOString(entity.releaseDate) : '';
                const seasonNumber = entity.showAttributes
                    ? entity.showAttributes.find((attribute) => attribute.key === 'seasonNo')?.value || ''
                    : '';
                return {
                    key: index,
                    ...entity,
                    publictitle: entity.name,
                    productiontype: entity.productionType,
                    productionStatus: entity.productionStatus,
                    otherTitles: otherTitlesValue,
                    releaseDate: parseISO(entity.releaseDate),
                    releaseYear: entityReleaseDate,
                    ProductionYears: entity.productionYears,
                    seasonNumber: seasonNumber,
                    productionGroup: entity.productionGroup?.label || entity.productionGroup?.productionReference?.id,
                    createdOn: parseISO(entity.createdOn),
                    updatedOn: parseISO(entity.updatedOn),
                    security: entity.security,
                    _raw: entity
                };
            }),
        [raw]
    );

    const columns = React.useMemo(() => {
        const defaultCols = defaultColumns.map((col) => ({
            ...col,
            sortable: findIndex(raw?.results?.summary?.sortOptions, { id: col.field }) > -1
        }));
        return defaultCols;
    }, [defaultColumns, raw?.results?.summary?.sortOptions]);

    const customActions = [];

    const attachmentsAction = (params: GridRowParams): ReactElement<GridActionsCellItemProps> => {
        return (
            <GridActionsCellItem
                icon={<Icons.AttachFileOutlined fontSize={'small'} />}
                label={attachmentLabel}
                showInMenu={isMobile}
                onClick={() => {
                    handleSidePanelOpening(params.row);
                    setSidePanelOpened('attachments');
                }}
            />
        );
    };
    const notesAction = (params: GridRowParams): ReactElement<GridActionsCellItemProps> => {
        return (
            <GridActionsCellItem
                icon={<Icons.Notes fontSize={'small'} />}
                label={notesLabel}
                showInMenu={isMobile}
                onClick={() => {
                    handleSidePanelOpening(params.row);
                    setSidePanelOpened('notes');
                }}
            />
        );
    };

    !isProductionMode && customActions.push(attachmentsAction, notesAction);

    return (
        <WithSidePanel
            visibleSidePanelId={entityReference ? sidePanelOpened : undefined}
            panels={
                featurePermissions[AllPermissions.Read]
                    ? [
                          {
                              id: 'notes',
                              icon: Icons.Notes,
                              label: <I18n token="notes.title" />,
                              disabled: !entityReference,
                              component: (onClose) => (
                                  <Notes
                                      id={'notes-production'}
                                      title={<I18n token="notes.panel.title" values={{ entityName: rowEntityName }} />}
                                      entityReference={entityReference}
                                      onClose={() => {
                                          setEntityReference(undefined);
                                          onClose();
                                      }}
                                      featurePermissions={featurePermissions}
                                      entityPermissions={ALL_PERMISSIONS}
                                  />
                              )
                          },
                          {
                              id: 'attachments',
                              icon: Icons.Attachments,
                              label: <I18n token="attachments.title" />,
                              tooltip: entityReference ? (
                                  <I18n token="atttachments.close" />
                              ) : (
                                  <I18n token="attachment.from.grid" />
                              ),
                              disabled: !entityReference,
                              component: (onClose) => (
                                  <Attachments
                                      id={'attachments-production'}
                                      entityReference={entityReference}
                                      title={
                                          <I18n
                                              token="attachments.panel.title"
                                              values={{ entityName: rowEntityName }}
                                          />
                                      }
                                      onClose={() => {
                                          setEntityReference(undefined);
                                          onClose();
                                      }}
                                      onSave={attachmentSave}
                                      useGetAttachments={useGetAttachments}
                                      useDeleteAttachments={useDeleteAttachments}
                                  />
                              )
                          }
                      ]
                    : []
            }
            leftToolbar={undefined}
            hideHeader={true}
        >
            <V sx={{ height: '100%', minWidth: '1px' }}>
                <DataGrid
                    id={'Production'}
                    {...props}
                    asIsColumns={['managed', 'avatar']}
                    clickableColumn={'publictitle'}
                    feature={feature}
                    rowCount={raw?.results?.summary?.total}
                    rows={raw?.results ? rows : []}
                    columns={columns}
                    defaultColumnVisibility={columnVisibility}
                    sortingMode={'server'}
                    onDetails={(id, row) => {
                        if (application.home === SUPPLIER.home) {
                            changeRoute(`${application.home}/production/${id}/details`);
                        } else if (application.home === PROFILES.home) {
                            const rowPermissions = getRowPermissions(row.security);
                            const isReadOnly = rowPermissions[AllPermissions.Read] && !rowPermissions[AllPermissions.Edit];
                            changeRoute(`${application.home}/production/${id}/details`, undefined, false, {
                                isReadOnly: isReadOnly
                            });
                        } else if (productionMode === ProductionModes.production) {
                            const routePrefix = row?.managedProduction ? 'workspace' : 'production';
                            const productionMode = row?.managedProduction
                                ? ProductionModes.workspace
                                : ProductionModes.production;

                            changeRoute(`${application.home}/${routePrefix}/${id}/details`, {
                                productionMode
                            });
                        } else {
                            changeRoute(`${application.home}/workspace/${id}/details`, {
                                productionMode: ProductionModes.workspace,
                                'prod-details_tab': '0'
                            });
                        }
                    }}
                    customActions={customActions}
                    onEdit={(id: string) => {
                        setEditingId(id);
                    }}
                />
            </V>
            {!!editingId && <Editor editingId={editingId} setEditingId={setEditingId} />}
        </WithSidePanel>
    );
};

export default () => {
    const searchkit = new SearchkitClient();
    const { application, activeOrganizationAccount } = useContext(UserContext);
    const { searchParams, changeSearch } = useHistory();
    const { allowWIP } = useContext(EnvironmentPermissionContext);
    const { isMobile } = useStyling();

    const preFilters = useProductionPreFilters();
    const productionMode = searchParams.get('productionMode');
    const isProductionMode = productionMode === ProductionModes.production || application.home === SUPPLIER.home;

    const [deleteProduction] = useMutation(deleteQuery, { client: client });
    const [isSchedulerProductionRefetchNeeded, setIsSchedulerProductionRefetchNeeded] = useRecoilState(
        isSchedulerProductionRefetchNeededAtom
    );

    useEffect(() => {
        if (!isProductionMode && !productionMode) {
            changeSearch({ productionMode: ProductionModes.workspace });
        }
    }, [isProductionMode]);

    const onDelete = (id: string) => {
        return new Promise<void>((resolve, reject) => {
            deleteProduction({
                variables: {
                    id: id
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then(() => {
                    console.log(`Deleted ${id}`);
                    deleteProductionResourcesFromSchedulerProductionData(
                        id,
                        activeOrganizationAccount,
                        setIsSchedulerProductionRefetchNeeded,
                        isSchedulerProductionRefetchNeeded
                    );
                    resolve();
                })
                .catch((err) => {
                    console.log(`Failed to delete ${id}`);
                    console.log(err);
                    reject();
                });
        });
    };

    return (
        <SchedulerProvider>
            <ApolloProvider client={searchClient} key={productionMode}>
                <SearchkitProvider client={searchkit}>
                    {productionMode === ProductionModes.workspace && application.home === PLANNING.home ? (
                        <ListView
                            id={'workspace'}
                            feature="Production"
                            entity="Production"
                            uri={`${application.home}/workspace`}
                            query={slimResultsQuery('Production', true)}
                            EntityTableRenderer={Table}
                            preFilters={preFilters}
                            immutableFilters={[...preFilters, { identifier: 'managedProduction', value: 'true' }]}
                            onDelete={onDelete}
                            enableImport={true}
                            ImportView={ProductionImport}
                            defaultSortModel={{ field: 'updatedOn', sort: 'desc' }}
                            createUri
                            //quickFilters={['projectStatus', 'projectOwnerFirstName', 'projectOwnerLastName']} Do not need it now. Might need later
                            quickFilters={['projectStatus']}
                            EntityListRenderer={(props) => <List {...props} />}
                            containerSx={{ pr: isMobile ? 1 : 2 }}
                        />
                    ) : (
                        <ListView
                            id={'production'}
                            feature="Production"
                            entity="Production"
                            uri={`${application.home}/production`}
                            query={slimResultsQuery('Production', true)}
                            EntityTableRenderer={Table}
                            preFilters={preFilters}
                            immutableFilters={[...preFilters, { identifier: 'managedProduction' }]}
                            onDelete={onDelete}
                            enableImport={true}
                            ImportView={ProductionImport}
                            defaultSortModel={{ field: 'updatedOn', sort: 'desc' }}
                            createUri
                            containerSx={{ pr: isMobile ? 1 : 2 }}
                        />
                    )}
                </SearchkitProvider>
            </ApolloProvider>
        </SchedulerProvider>
    );
};

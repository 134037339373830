import { Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { Attachment, AttachmentInput } from 'sr-types/lib/production/v1/graphql';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import Confirm from '../components/Confirm';
import SidePanel from '../components/layout/SidePanel';
import Loading from '../components/Loading';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import { H, V } from '../Layout';
import { acceptedFilesMock } from './attachmentsApi';
import AttachmentsEditor from './AttachmentsEditor';
import AttachmentsRenderer from './AttachmentsRenderer';
import { isNil } from 'lodash';

type AttachmentsProps = {
    id: string;
    entityReference: Reference;
    onSave: (entityId: string, input: AttachmentInput[]) => Promise<any>;
    onClose: () => void;
    title?: string | ReactElement;
    maxFileSize?: number;
    acceptedFiles?: Array<string>;
    filesLimit?: number;
    attachmentsInState?: Array<AttachmentInput>;
    useGetAttachments?: (entityId: string, skip: boolean) => any;
    onDeleteAttachmentFromState?: (attachments: AttachmentInput[], input: AttachmentInput) => AttachmentInput[];
    useDeleteAttachments?: () => any;
    disableDropzone?: boolean;
};

export default (props: AttachmentsProps) => {
    const {
        entityReference,
        title = <I18n token="attachments.title" />,
        onClose,
        onSave,
        maxFileSize = 20 * 1024 * 1024,
        acceptedFiles = acceptedFilesMock,
        filesLimit = 10,
        attachmentsInState = [],
        useGetAttachments = () => {},
        useDeleteAttachments = () => {},
        onDeleteAttachmentFromState = undefined,
        disableDropzone = false
    } = props;

    const attachmentsResponse = useGetAttachments(entityReference?.id, !entityReference?.id);
    const deleteAttachmentResponse = useDeleteAttachments();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [fileToDelete, setFileToDelete] = useState<AttachmentInput>();
    const [attachments, setAttachments] = useState<AttachmentInput[]>([...attachmentsInState]);

    useEffect(() => {
        if (attachmentsResponse && attachmentsResponse.data) {
            const attachments = isNil(attachmentsResponse.data.attachments)
                ? []
                : [...attachmentsResponse.data.attachments];
            if (attachmentsInState.length) {
                const combinedAttachments = [...attachments, ...attachmentsInState];
                setAttachments(combinedAttachments);
            } else {
                setAttachments(attachmentsResponse.data.attachments);
            }
        }
    }, [attachmentsInState, attachmentsResponse]);

    const sidePanelActions = [
        {
            icon: Icons.Close,
            label: <I18n token="dialog.close" />,
            onClick: onClose
        }
    ];

    const onDeleteAttachment = (input: AttachmentInput) => {
        if (onDeleteAttachmentFromState) {
            const filteredAttachments: AttachmentInput[] = onDeleteAttachmentFromState(attachments, input);
            setAttachments(filteredAttachments);
        } else {
            deleteAttachmentResponse?.deleteAttachment(entityReference.id, input);
        }

        setIsDeleteModalOpen(false);
    };

    return (
        <SidePanel id={'attachments-container'} title={title} actions={sidePanelActions}>
            <V sx={{ p: 1, pl: 0, gap: 1, overflow: 'auto' }}>
                <AttachmentsEditor
                    entityReference={entityReference}
                    maxFileSize={maxFileSize}
                    acceptedFiles={acceptedFiles}
                    filesLimit={filesLimit}
                    insideSidePanel={true}
                    onSave={onSave}
                    disableDropzone={disableDropzone}
                />
                {attachmentsResponse.loading ? (
                    <Loading />
                ) : (
                    <V sx={{ pl: 1, gap: 1, overflow: 'auto' }}>
                        {attachments?.length ? (
                            attachments?.map((file: Attachment, index: number) => (
                                <AttachmentsRenderer
                                    key={index}
                                    file={file}
                                    onDelete={() => {
                                        const { name, type, size, entityReference } = file;
                                        setFileToDelete({ entityReference, name, type, size });
                                        setIsDeleteModalOpen(true);
                                    }}
                                />
                            ))
                        ) : (
                            <H sx={{ justifyContent: 'center' }}>
                                <Typography color={'grey'}> No files attached </Typography>
                            </H>
                        )}
                    </V>
                )}
            </V>
            <Confirm
                open={isDeleteModalOpen}
                title={<I18n token="delete.confirm.title" />}
                okLabelI18n="delete.confirm.title"
                body={
                    <Typography>
                        <I18n token="delete.confirm.message" />
                    </Typography>
                }
                onConfirm={() => onDeleteAttachment(fileToDelete)}
                onCancel={() => setIsDeleteModalOpen(false)}
            />
        </SidePanel>
    );
};

import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { FormContext } from '../common/form/FormContext';
import FormModal from '../common/form/FormModal';
import useHistory from '../common/utils/useHistory';
import { clientWithoutAuth, saveInquiryResponse } from '../supply/opportunity/opportunity';
import { inquiryResponseStatuses } from '../supply/opportunity/opportunityInquiry';
import InquiryPreview from './InquiryPreview';

export default ({ data, dataLoading, token, thankYouVisible, setIsPreview, setThankYouVisible }) => {
    const { searchParams } = useHistory();
    const { state, validation } = useContext(FormContext);

    const inquiryId = searchParams.get('inquiryId');

    const [save, { loading: saving }] = useMutation(saveInquiryResponse, {
        client: clientWithoutAuth,
        context: {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    });

    return (
        <FormModal
            id="inquiryResponse"
            title=""
            isOpen={true}
            hideDefaultButtons={thankYouVisible}
            isSaving={saving}
            isLoading={dataLoading}
            onSave={() => {
                save({
                    variables: {
                        inquiryResponse: {
                            inquiryId,
                            status: state.status.id
                        }
                    }
                }).then(() => {
                    setIsPreview(false);
                    setThankYouVisible(true);
                });
            }}
            isSaveDisabled={
                data?.inquiry?.inquiry?.status === inquiryResponseStatuses.yes ||
                data?.inquiry?.inquiry?.status === inquiryResponseStatuses.no ||
                !validation?.isValid
            }
        >
            <InquiryPreview data={data?.inquiry} />
        </FormModal>
    );
};

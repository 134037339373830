import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { H } from '../common/Layout';
import { IconButton, SvgIconTypeMap } from '@mui/material';
import { Icons } from '../common/icons/Icons';
import { useI18n } from '../common/i18n/I18n';
import React, { useContext } from 'react';
import { LineItemExtended } from './ShortlistLineItems';
import { RequestAvailability } from '../common/icons/RequestAvailability';
import { RequestSent } from '../common/icons/RequestSent';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ResponseReceived } from '../common/icons/ResponseReceived';
import { EnvironmentPermissionContext } from '../common/auth/EnvironmentPermissionContext';

export enum InquiryStatusEnum {
    default = 'default',
    empty = '-',
    available = 'Available',
    notAvailable = 'Not Available',
    requestSent = 'Request Sent',
    alternateProposed = 'Alternate Proposed',
    informationRequested = 'Information Requested'
}

export type InquiryStatus = (typeof InquiryStatusEnum)[keyof typeof InquiryStatusEnum];

type Type = {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    disabled: boolean;
};

type InquiryStatusType = {
    [key in InquiryStatus]: Type;
};

export const inquiryStatus: InquiryStatusType = {
    [InquiryStatusEnum.default]: { icon: RequestAvailability, disabled: true },
    [InquiryStatusEnum.empty]: { icon: RequestAvailability, disabled: false },
    [InquiryStatusEnum.available]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.notAvailable]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.requestSent]: { icon: RequestSent, disabled: true },
    [InquiryStatusEnum.alternateProposed]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.informationRequested]: { icon: ResponseReceived, disabled: true }
};

export default (onNotesIconClick, invitePerson, inviteFeature = false): GridColDef[] => {
    const { allowWIP } = useContext(EnvironmentPermissionContext);
    const icons: GridColDef = {
        field: 'icons',
        headerName: '',
        minWidth: 100,
        width: 100,
        resizable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams<LineItemExtended>) => {
            const Icon = inquiryStatus[params.row.inquiryStatus]?.icon || inquiryStatus[InquiryStatusEnum.default].icon;
            let inquiryDisabled = inquiryStatus[params.row.inquiryStatus]?.disabled;
            if (!inquiryDisabled && allowWIP && params.row.isOrganization && !params.row.hasAccount) {
                inquiryDisabled = true;
            }

            return (
                <H sx={{ gap: 0.5 }}>
                    <IconButton size={'medium'} component="span" onClick={() => onNotesIconClick(params.row)}>
                        <Icons.Notes color={'success'} fontSize={'small'} />
                    </IconButton>
                    {inviteFeature &&
                    (params.row.isPerson ||
                        (params.row.isOrganization && allowWIP && params.row.isItemSharedInDirectory)) ? (
                        <IconButton
                            size={'small'}
                            component="span"
                            onClick={
                                inquiryStatus[params.row.inquiryStatus]?.disabled
                                    ? () => {}
                                    : () => invitePerson(params.row)
                            }
                            disabled={inquiryDisabled}
                        >
                            <Icon color={inquiryDisabled ? 'disabled' : 'primary'} fontSize={'medium'} />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </H>
            );
        }
    };
    const name: GridColDef = {
        field: 'name',
        headerName: useI18n('shortlist.name'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };
    const itemStatus: GridColDef = {
        field: 'itemStatus',
        headerName: useI18n('shortlist.line.item.status'),
        flex: 1,

        minWidth: 100,
        sortable: true
    };
    const about: GridColDef = {
        field: 'about',
        valueGetter: ({ row }) => row.summary?.about,
        headerName: useI18n('shortlist.about'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };
    const availability: GridColDef = {
        field: 'availability',
        headerName: useI18n('shortlist.line.item.availability'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };

    return inviteFeature ? [icons, name, itemStatus, availability, about] : [icons, name, itemStatus, about];
};

import {
    AlternateIdInput,
    ContactInfoInput,
    DateRangeInput,
    DeiInfoInput,
    MembershipInput,
    PersonBasicProfileInput,
    ReferenceInput,
    EmailInput,
    PhoneInput,
    PersonInput,
    CreativeCredit,
    Experience
} from 'sr-types/lib/person/v1/graphql';
import { Reference } from '../../common/reference/reference';
import { failedToastMessage, successToastMessage } from '../../common/utils/commonUtils';
import { createURLConstructor, findAlternateId, IMDB_LABEL, LINKEDIN_LABEL } from './personAlternateIds';

export interface CommonExtendedFields {
    Imdb: string;
    LinkedIn: string;
    memberships: ReferenceInput[];
    imdbAlternateId: AlternateIdInput;
    linkedInAlternateId: AlternateIdInput;
    imdbProfileId: string;
    linkedInProfileId: string;
    genderCustom: string;
    ethnicityCustom: string;
    disabilityStatusDescription: string;
}
export interface BasicInfoInputExtended extends Omit<PersonBasicProfileInput, 'memberships'>, CommonExtendedFields {}
export interface PersonInputExtended extends Omit<PersonInput, 'memberships'>, CommonExtendedFields {}

export const EMPTY_STATE = {
    memberships: [],
    Imdb: '',
    LinkedIn: '',
    imdbAlternateId: undefined,
    linkedInAlternateId: undefined,
    imdbProfileId: undefined,
    linkedInProfileId: undefined,
    genderCustom: undefined,
    ethnicityCustom: undefined,
    disabilityStatusDescription: undefined
};

export const OTHER = 'Other';
export const YES = 'Yes';

export const MUI_DATE_MONTH_AND_DAY = '0101';

export const stateToPersonBasicInfoInput = (personBasicInfo) => {
    const {
        contactInfo,
        Imdb,
        imdbAlternateId,
        linkedInAlternateId,
        LinkedIn,
        imdbProfileId,
        linkedInProfileId,
        alternateIds,
        deiInfo,
        genderCustom,
        ethnicityCustom,
        disabilityStatusDescription,
        memberships,
        summary,
        ...state
    } = personBasicInfo;

    const contactInfoDestructured: ContactInfoInput =
        personBasicInfo && constructContactInfo(personBasicInfo.contactInfo);

    const deiInfoDestructured: DeiInfoInput =
        deiInfo && handleDeiInfoValuesToInput(deiInfo, disabilityStatusDescription, ethnicityCustom, genderCustom);

    const constructedAlternateIds = [];
    imdbAlternateId && addAlternateId(constructedAlternateIds, imdbAlternateId, imdbProfileId, IMDB_LABEL);
    linkedInAlternateId &&
        addAlternateId(constructedAlternateIds, linkedInAlternateId, linkedInProfileId, LINKEDIN_LABEL);

    const membershipObjects = memberships && createMembershipObject(memberships);
    const summaryObject = summary && constructSummary(summary.headline, summary.about);

    return {
        contactInfo: contactInfoDestructured,
        alternateIds: constructedAlternateIds,
        deiInfo: deiInfoDestructured,
        memberships: membershipObjects,
        summary: summaryObject,
        ...state
    };
};

export const constructContactInfo = (contactInfo: ContactInfoInput) => {
    return {
        email:
            contactInfo && contactInfo.email && contactInfo.email.address
                ? {
                      address: contactInfo.email.address,
                      typeString: 'Business',
                      verified: false
                  }
                : null,
        phone:
            contactInfo && contactInfo.phone && contactInfo.phone.number
                ? {
                      number: contactInfo.phone.number,
                      typeString: 'Mobile'
                  }
                : null
    };
};

export const handleDeiInfoValuesToInput = (
    deiInfo: DeiInfoInput,
    disabilityStatusDescription: string,
    ethnicityCustom: string,
    genderCustom: string
) => {
    const { birthYear, disabilityStatus, ethnicity, gender, socioEconomicStatus } = deiInfo;
    const deiInfoDestructured: DeiInfoInput = {
        birthYear: (birthYear && birthYear.substring(0, 4)) || '',
        disabilityStatus: disabilityStatus === YES ? disabilityStatusDescription : disabilityStatus || '',
        ethnicity: ethnicity === OTHER ? ethnicityCustom : ethnicity || '',
        gender: gender == OTHER ? genderCustom : gender || '',
        socioEconomicStatus: socioEconomicStatus || ''
    };
    return deiInfoDestructured;
};

export const addAlternateId = (
    listOfAlternateId: Array<AlternateIdInput>,
    alternateId: AlternateIdInput,
    profileId: string,
    fieldLabel: string
) => {
    let validUrlLink: string;
    if (profileId) {
        const origin = createURLConstructor(alternateId.url)?.origin;
        validUrlLink = `${origin}/${fieldLabel === IMDB_LABEL ? 'name' : 'in'}/${profileId}`;
    }
    return listOfAlternateId.push({ ...alternateId, url: validUrlLink || alternateId.url });
};

export const createMembershipObject = (memberships: Array<Reference>) => {
    return memberships && memberships.map((m) => ({ organizationReference: m }));
};

export const constructSummary = (headline: string = '', about: string = '') => {
    return { headline, about };
};

export const updatePersonBasicInfo = (state, onClose, updatePersonInfo) => {
    const personBasicInfoInput = stateToPersonBasicInfoInput(state);
    updatePersonInfo({
        input: {
            ...personBasicInfoInput
        },
        onSuccess: () => successToastMessage('person.edit.success'),
        onError: () => failedToastMessage('person.edit.error'),
        onClose: onClose
    });
};

export const experienceToInput = (experience) => {
    const { dateRange, ...state } = experience;
    delete state.__typename;

    const dateRangeDestructured: DateRangeInput = dateRange.start !== '' && dateRange.end !== '' ? dateRange : null;

    return { dateRange: dateRangeDestructured, ...state };
};

export const extractPersonBasicInfo = (person) => {
    const {
        availableToHire,
        contactInfo,
        identity,
        location,
        name,
        professionalRoles,
        profileAttributes,
        profileImageReference,
        summary,
        alternateIds,
        deiInfo,
        memberships
    }: PersonBasicProfileInput = person;

    const imdb = alternateIds && alternateIds.length && findAlternateId(alternateIds, IMDB_LABEL);
    const linkedIn = alternateIds && alternateIds.length && findAlternateId(alternateIds, LINKEDIN_LABEL);

    const membershipsWithReferencesExtracted: ReferenceInput[] =
        memberships && memberships.map((m: MembershipInput) => m.organizationReference);

    const email: EmailInput =
        contactInfo && contactInfo.email
            ? contactInfo.email
            : {
                  address: '',
                  typeString: '',
                  verified: false
              };

    const phone: PhoneInput =
        contactInfo && contactInfo.phone
            ? contactInfo.phone
            : {
                  number: '',
                  typeString: ''
              };

    const personBasicInfo = {
        availableToHire,
        contactInfo: {
            email,
            phone
        },
        identity,
        location,
        name,
        professionalRoles,
        profileAttributes,
        profileImageReference,
        summary,
        Imdb: imdb && imdb.url,
        LinkedIn: linkedIn && linkedIn.url,
        imdbAlternateId: imdb,
        linkedInAlternateId: linkedIn,
        alternateIds,
        deiInfo: deiInfo?.birthYear
            ? { ...deiInfo, birthYear: deiInfo.birthYear + MUI_DATE_MONTH_AND_DAY }
            : { ...deiInfo },
        memberships: memberships ? membershipsWithReferencesExtracted : [],
        imdbProfileId: undefined,
        linkedInProfileId: undefined,
        genderCustom: undefined,
        ethnicityCustom: undefined,
        disabilityStatusDescription: undefined
    };
    return personBasicInfo;
};

export const removeEmptyCreativeCredits = (creativeCredits: CreativeCredit[]) => {
    return creativeCredits.filter((c) => !isCreativeCreditObjectEmpty(c));
};

export const removeEmptyExperiences = (experiences: Experience[]) => {
    return experiences.filter((e) => !isExperienceObjectEmpty(e));
};

const isExperienceObjectEmpty = (experience: Experience) => {
    return (
        experience &&
        experience.location === null &&
        experience.experienceName === '' &&
        experience.organizationReference === null &&
        experience.jobType === '' &&
        experience.dateRange.start === '' &&
        experience.dateRange.end === '' &&
        experience.keyExperience === false &&
        (experience.professionalRoles === null ||
            (experience.professionalRoles?.length && experience.professionalRoles[0] === '')) &&
        experience.title === '' &&
        experience.department === ''
    );
};

const isCreativeCreditObjectEmpty = (creativeCredit: CreativeCredit) => {
    return (
        creativeCredit &&
        creativeCredit.location === null &&
        creativeCredit.showName === '' &&
        creativeCredit.showType === '' &&
        creativeCredit.showReference === null &&
        creativeCredit.dateRange.start === '' &&
        creativeCredit.dateRange.end === '' &&
        creativeCredit.keyCredit === false &&
        (creativeCredit.professionalRoles === null ||
            (creativeCredit.professionalRoles?.length && creativeCredit.professionalRoles[0] === '')) &&
        creativeCredit.department === ''
    );
};

export const organizationMembershipsFilters = {
    expressions: [
        {
            field: 'organizationTypes',
            value: {
                values: ['Guild', 'Union']
            }
        },
        { field: 'isSharedInDirectory', value: { values: ['true'] } }
    ]
};

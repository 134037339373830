import { FormControlLabel, Switch, Typography } from '@mui/material';
import { GridColDef, MuiEvent } from '@mui/x-data-grid-pro';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { isEmpty } from 'lodash';
import papa from 'papaparse';
import React, { useContext, useEffect, useState } from 'react';
import { rowsToContacts } from '../../supply/opportunity/details/ContactListUtils';
import { V } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import DropzoneTextArea from '../components/DropzoneTextArea';
import Loading from '../components/Loading';
import FormModal from '../form/FormModal';
import { Icons } from '../icons/Icons';
import DataGrid from '../list/DataGrid';
import { generateTempId } from '../utils/commonUtils';
import upload from '../utils/upload';
import SearchLocation from '../../supply/product/SearchLocation';

export const PROCESS_URL = '/storage/v1/process';

export const MIME_TO_EXT = {
    'text/csv': ['.csv', '.tsv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};

export default ({
    feature,
    onSave,
    onClose,
    backAction,
    isUpload,
    primaryColDefs,
    altColDefLabel = undefined,
    altColDefs,
    columnVisibility = {},
    rowsToEntities,
    doSave,
    locationSearch = false,
    bulkSave,
    placeholder = '',
    title = 'Add',
    saveLabel = 'Update',
    editLabel = 'Edit',
    setImportDetails = undefined,
    setShowImportDetailsDialog = undefined,
    disableTextBasedSelector = false,
    disableBulkSave = false,
    validateImportedData = undefined,
    existingData = [],
    dataToImport = undefined,
    disableDragNDrop = false
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(isUpload);
    const [hasHeaders, setHasHeaders] = useState(false);
    const [isAltColDef, setIsAltColDef] = useState(false);
    const [text, setText] = useState(dataToImport || '');
    const [defaultColumns, setDefaultColumns] = useState<GridColDef[]>(primaryColDefs);
    const [rows, setRows] = useState([]);
    const [searchLocations, setSearchLocations] = useState([]);
    const [errors, setErrors] = useState(undefined);
    const { activeOrganizationAccount, getPreference } = useContext(UserContext);
    const [isDataImported, setIsDataImported] = useState(false);
    const [hasHeadersInTable, setHasHeadersInTable] = useState(false);
    const { theme } = useStyling();
    const [invalidData, setInvalidData] = useState(false);
    const columnPreferences = getPreference('data-import-grid', 'my-columns');
    useEffect(() => {
        setDefaultColumns(isAltColDef ? altColDefs : primaryColDefs);
    }, [isAltColDef]);

    const showColumnsBasedOnPreference = (defaultColumns: GridColDef[]) => {
        return defaultColumns.filter((col) => columnPreferences[col?.field] !== false);
    };

    const createPreview = (hasHeadersInTable = false) => {
        setRows([]);
        if (text) {
            const cleanedInput = text.replace(/,\s+"/g, ',"');
            const parsed = papa.parse(cleanedInput, {
                skipEmptyLines: 'greedy'
            });
            let lines = [...parsed.data];
            if (hasHeadersInTable) {
                lines.shift();
            } else {
                lines = [...parsed.data];
            }
            if (hasHeaders) {
                lines.shift();
            }

            const r = lines.map((line) => {
                const index = 'IG_' + generateTempId();
                const mapValueToColumns = (columns: GridColDef[]) => {
                    return columns.forEach((c: GridColDef, index: number) => {
                        row[c.field] = line[index]?.trim();
                    });
                };

                const row = { id: index };
                columnPreferences
                    ? mapValueToColumns(showColumnsBasedOnPreference(defaultColumns))
                    : mapValueToColumns(defaultColumns);
                return row;
            });
            if (r.length) {
                setRows(r);
            }
        }
    };

    useEffect(() => {
        if (validateImportedData) {
            validateImportedData(rows, existingData, setInvalidData, setErrors);
        }
        if (locationSearch && rows.length) {
            const locationS = rows.filter((row) => {
                return !isEmpty(row.location);
            });
            setSearchLocations(locationS);
        }
    }, [rows]);

    const localReadFile = (file) => {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function (evt) {
            if (evt.target.readyState === evt.target.DONE) {
                console.log('onload:', evt);
                // @ts-ignore
                setText(evt.target.result);
            }
        };
        reader.onerror = function (evt) {
            console.log('error reading file:', evt);
        };
    };

    const editAction = {
        label: editLabel,
        onClick: () => {
            setErrors(undefined);
            setIsPreview(false);
            setInvalidData(false);
        }
    };
    const uploadAction = {
        label: 'Import',
        Icon: Icons.FileUpload,
        onClick: () => {
            setOpenUploadDialog(true);
            //Import process starts again
            if (isDataImported) {
                setIsPreview(false);
                setIsDataImported(false);
                setHasHeadersInTable(false);
                setText('');
            }
        }
    };

    useEffect(() => {
        if (isDataImported) {
            createPreview();
            setIsPreview(true);
        }
    }, [isDataImported]);

    const EmptyNoRowsOverlay = () => {
        return <span></span>;
    };

    return (
        <FormModal
            id={'import-dialog'}
            width={'80%'}
            title={title}
            isOpen={true}
            expanded={true}
            onClose={onClose}
            isLoading={false}
            saveLabel={isPreview ? saveLabel : 'Preview'}
            isSaveDisabled={isSaving || invalidData}
            onSave={() => {
                if (isPreview) {
                    if (disableBulkSave) {
                        onClose();
                        const contacts = rowsToContacts(rows, isAltColDef ? altColDefs : primaryColDefs);
                        onSave(contacts);
                    } else {
                        doSave(
                            setIsSaving,
                            rows,
                            isAltColDef,
                            altColDefs,
                            primaryColDefs,
                            defaultColumns,
                            setErrors,
                            onClose,
                            onSave,
                            bulkSave,
                            activeOrganizationAccount,
                            setImportDetails,
                            setShowImportDetailsDialog
                        );
                    }
                } else {
                    createPreview();
                    setOpenUploadDialog(false);
                    setIsPreview(true);
                }
            }}
            isSaving={false}
            additonalLeftsideActions={backAction}
            additionalActions={isPreview && !isDataImported ? [editAction] : dataToImport ? [] : [uploadAction]}
        >
            <V sx={{ height: '100%', pl: 2, pr: 2, gap: 1 }}>
                {isSaving ? (
                    <Loading />
                ) : isPreview ? (
                    <V sx={{ height: '100%' }}>
                        <DataGrid
                            id={'data-import-preview'}
                            containerSx={{
                                width: '100%',
                                '& .cellInvalid': {
                                    backgroundColor: theme.palette.error.light,
                                    fontWeight: 'bold'
                                }
                            }}
                            feature={feature}
                            rowCount={rows.length}
                            rows={rows}
                            columns={columnPreferences ? showColumnsBasedOnPreference(defaultColumns) : defaultColumns}
                            defaultColumnVisibility={columnVisibility}
                            disableColumnMenu
                            disablePagination
                            serverPagination={false}
                            includeActions={false}
                            errors={errors}
                        />
                        {disableTextBasedSelector && (
                            <FormControlLabel
                                sx={{ flexGrow: 0, ml: 0 }}
                                control={<Switch size={'small'} />}
                                label={'My data includes header row'}
                                checked={hasHeadersInTable}
                                onChange={(event, newValue) => {
                                    createPreview(newValue);
                                    setHasHeadersInTable(newValue);
                                }}
                            />
                        )}
                        {isEmpty(errors) ? (
                            <React.Fragment />
                        ) : (
                            <Typography sx={{ mt: 1, pl: 0.5, pr: 0.5, backgroundColor: theme.palette.error.light }}>
                                Marked fields require attention.
                            </Typography>
                        )}
                    </V>
                ) : (
                    <>
                        <DataGrid
                            id={'data-import-grid'}
                            slots={{
                                noRowsOverlay: EmptyNoRowsOverlay
                            }}
                            containerSx={{
                                minHeight: '56px',
                                width: '100%',
                                flexGrow: 0,
                                '.MuiDataGrid-overlay': {
                                    top: 8
                                },
                                '.MuiDataGrid-footerContainer': {
                                    border: 'none'
                                },
                                '.MuiDataGrid-virtualScroller': {
                                    overflow: 'hidden'
                                },
                                '.MuiDataGrid-main': {
                                    overflow: 'visible'
                                }
                            }}
                            feature={feature}
                            rowCount={0}
                            rows={[]}
                            hideFooter
                            columns={defaultColumns.map((c) => ({ ...c, sortable: false }))}
                            defaultColumnVisibility={columnVisibility}
                            serverPagination={false}
                            includeActions={false}
                            disablePagination={true}
                            disableColumnResize={true}
                            onColumnOrderChange={(colDefs: GridStateColDef[], event: MuiEvent) => {
                                setDefaultColumns(colDefs);
                                event.defaultMuiPrevented = true;
                            }}
                        />
                        <DropzoneTextArea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            onDropRead={(newText) => setText(newText)}
                            placeholder={placeholder}
                            converter={(file) => {
                                return upload(activeOrganizationAccount, PROCESS_URL, file);
                            }}
                            onError={(msg) => {
                                console.error(msg);
                            }}
                            triggerUploadDialog={openUploadDialog}
                            setOpenUploadDialog={setOpenUploadDialog}
                            accept={MIME_TO_EXT}
                            setIsDataImported={setIsDataImported}
                            disableTextBasedSelector={disableTextBasedSelector}
                        />
                        {!dataToImport && (
                            <FormControlLabel
                                sx={{ flexGrow: 0, ml: 0 }}
                                control={<Switch size={'small'} />}
                                label={'My data includes header row'}
                                checked={hasHeaders}
                                onChange={(event, newValue) => setHasHeaders(newValue)}
                            />
                        )}
                        {altColDefLabel && (
                            <FormControlLabel
                                sx={{ flexGrow: 0, ml: 0 }}
                                control={<Switch size={'small'} />}
                                label={altColDefLabel}
                                checked={isAltColDef}
                                onChange={(event, newValue) => setIsAltColDef(newValue)}
                            />
                        )}
                    </>
                )}
            </V>
            {searchLocations.length === 0 ? (
                <></>
            ) : (
                searchLocations.map((row, index) => {
                    return <SearchLocation key={index} row={row} />;
                })
            )}
        </FormModal>
    );
};

import {
    Abc,
    AccessTimeOutlined,
    AccountCircleOutlined,
    AccountTree,
    AccountTreeOutlined,
    Add,
    AddCard,
    AddCircleOutline,
    AddOutlined,
    AdminPanelSettingsOutlined,
    AppsOutlined,
    ArrowBackOutlined,
    ArrowDownwardOutlined,
    ArrowDropDownOutlined,
    ArrowForwardOutlined,
    ArrowUpwardOutlined,
    ArticleOutlined,
    Assessment,
    AssignmentOutlined,
    AttachFileOutlined,
    AttachMoney,
    AudioFileOutlined,
    AutoFixHigh,
    BarChart,
    BookOnline,
    BuildCircleOutlined,
    BusinessOutlined,
    Cached,
    CalendarMonth,
    CalendarToday,
    CameraAlt,
    CancelOutlined,
    CategoryOutlined,
    CenterFocusStrongOutlined,
    Checkroom,
    Check,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    CheckCircleOutline,
    CheckOutlined,
    Checklist,
    ChevronLeft,
    ChevronRight,
    Clear,
    ClearAllOutlined,
    CloseOutlined,
    CollectionsBookmarkOutlined,
    ConfirmationNumber,
    ContactPageOutlined,
    ContentCopyOutlined,
    CropOutlined,
    DarkModeOutlined,
    DashboardOutlined,
    DeleteOutline,
    DeviceHub,
    DirectionsCar,
    Done,
    Download,
    Edit,
    EditAttributes,
    EditOutlined,
    Error,
    EventAvailable,
    EventAvailableOutlined,
    ExpandLessOutlined,
    ExpandMoreOutlined,
    FeedbackOutlined,
    FileDownloadOutlined,
    FileUploadOutlined,
    FilterAltOutlined,
    FolderOutlined,
    FolderZipOutlined,
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignJustifyOutlined,
    FormatAlignLeft,
    FormatAlignRight,
    FormatBold,
    FormatColorText,
    FormatItalic,
    FormatListBulleted,
    FormatListBulletedOutlined,
    FormatListNumbered,
    FormatStrikethrough,
    FormatUnderlined,
    Grid3x3Outlined,
    GroupAddOutlined,
    HelpOutlineOutlined,
    HideImage,
    HomeOutlined,
    HomeRepairService,
    Image,
    Info,
    Inventory2,
    Inventory2Outlined,
    KeyboardArrowDown,
    LanguageOutlined,
    Launch,
    LightModeOutlined,
    LocationOn,
    LockOutlined,
    LogoutOutlined,
    LoopOutlined,
    ManageAccountsOutlined,
    MapsHomeWorkOutlined,
    MenuOutlined,
    MoreVertOutlined,
    MovieCreation,
    NewspaperOutlined,
    NoteAdd,
    NotesOutlined,
    OpenInNewOutlined,
    OpenWith,
    Password,
    PeopleOutlineOutlined,
    PetsOutlined,
    PictureAsPdfOutlined,
    PivotTableChart,
    Place,
    PublicOutlined,
    QuestionMark,
    Redo,
    Refresh,
    RsvpOutlined,
    SaveOutlined,
    ScienceOutlined,
    SearchOutlined,
    SecurityOutlined,
    SellOutlined,
    SettingsApplications,
    SettingsOutlined,
    ShareOutlined,
    ShopTwo,
    ShoppingBasketOutlined,
    SortByAlphaOutlined,
    SortOutlined,
    SpaceDashboardOutlined,
    Square,
    Star,
    StarOutline,
    StoreOutlined,
    Summarize,
    SupportAgent,
    SvgIconComponent,
    TerminalOutlined,
    TheaterComedy,
    TuneOutlined,
    Undo,
    UnfoldLessOutlined,
    UnfoldMoreOutlined,
    VideocamOutlined,
    ViewColumnOutlined,
    ViewTimeline,
    Visibility,
    VisibilityOff,
    WidgetsOutlined,
    Workspaces,
    AttachFile,
    EmailOutlined,
    LocalPhone,
    PersonOutline,
    InfoOutlined,
    SettingsApplicationsOutlined,
    CalendarViewWeekOutlined,
    Recommend,
    BrokenImageOutlined,
    LocationOnOutlined,
    NoteAltOutlined
} from '@mui/icons-material';
import { Booking } from './Booking';
import { Cast } from './Cast';
import { Production } from './Production';
import { Service } from './Service';
import { RequestAvailability } from './RequestAvailability';
import { RequestSent } from './RequestSent';
import { ResponseReceived } from './ResponseReceived';
import { Success } from './Success';
import { DigitalAssets } from './DigitalAssets';
import { CreativeWork } from './CreativeWork';
import { Quote } from './Quote';
import { Order } from './Order';
import { LinkedIn } from './LinkedIn';
import { IMDb } from './IMDb';
import { ShowrunnrLogoMark } from './ShowrunnrLogoMark';
import { CardList } from './CardList';
import { GoogleLogo } from './GoogleLogo';
import { MicrosoftLogo } from './MicrosoftLogo';
import { ImportFile } from './ImportFile';

type IconsType = {
    [key in string]: SvgIconComponent;
};

export const Icons: IconsType = {
    Abc: Abc,
    AccessTime: AccessTimeOutlined,
    Account: SettingsOutlined,
    AccountCircle: AccountCircleOutlined,
    AccountTree: AccountTreeOutlined,
    AddButton: Add,
    AddCard: AddCard,
    AddCircle: AddCircleOutline,
    AddGroupOfPeople: GroupAddOutlined,
    AddOutlined: AddOutlined,
    AllColumns: CalendarViewWeekOutlined,
    Animal: PetsOutlined,
    ApplicationsSettings: SettingsApplications,
    AppsOutlined: AppsOutlined,
    ArrowBack: ArrowBackOutlined,
    ArrowDownward: ArrowDownwardOutlined,
    ArrowDropDownOutlined: ArrowDropDownOutlined,
    ArrowForward: ArrowForwardOutlined,
    ArrowUpward: ArrowUpwardOutlined,
    Assignment: AssignmentOutlined,
    AttachFileOutlined: AttachFileOutlined,
    Attachments: AttachFile,
    Attributes: EditAttributes,
    AudioFile: AudioFileOutlined,
    Bookings: Booking,
    BookOnline: BookOnline,
    BuildCircleOutlined: BuildCircleOutlined,
    BulletedList: FormatListBulleted,
    Business: BusinessOutlined,
    CardListIcon: CardList,
    Cache: Cached,
    Calendar: CalendarToday,
    CalendarMonth: CalendarMonth,
    CameraAlt: CameraAlt,
    Cancel: CancelOutlined,
    Cast: Cast,
    Catalog: Inventory2Outlined,
    CatalogPrev: Inventory2,
    Category: CategoryOutlined,
    Check: CheckOutlined,
    Checkbox: CheckBoxOutlined,
    CheckboxBlank: CheckBoxOutlineBlank,
    CheckCircle: CheckCircleOutline,
    CheckIcon: Check,
    Checklist: Checklist,
    ChevronLeft: ChevronLeft,
    ChevronRight: ChevronRight,
    ClearAll: ClearAllOutlined,
    ClearIcon: Clear,
    Close: CloseOutlined,
    CollectionBookmarks: CollectionsBookmarkOutlined,
    ConfirmationNumber: ConfirmationNumber,
    ContactPage: ContactPageOutlined,
    ContentCopy: ContentCopyOutlined,
    Crew: SupportAgent,
    Crop: CropOutlined,
    DarkMode: DarkModeOutlined,
    Default: QuestionMark,
    DefaultColumns: Recommend,
    Delete: DeleteOutline,
    Demo: ShopTwo,
    DeviceHub: DeviceHub,
    Directory: SortByAlphaOutlined,
    Document: ArticleOutlined,
    Done: Done,
    Download: Download,
    Edit: Edit,
    EditOutlined: EditOutlined,
    Email: EmailOutlined,
    Equipement: VideocamOutlined,
    ErrorIcon: Error,
    EventAvailable: EventAvailable,
    ExpandLess: ExpandLessOutlined,
    ExpandMore: ExpandMoreOutlined,
    Facilities: MapsHomeWorkOutlined,
    Facility: MapsHomeWorkOutlined,
    Feedback: FeedbackOutlined,
    FileDownload: FileDownloadOutlined,
    FileUpload: FileUploadOutlined,
    Filters: FilterAltOutlined,
    Folder: FolderOutlined,
    FormatAlignCenter: FormatAlignCenter,
    FormatAlignJustify: FormatAlignJustify,
    FormatAlignLeft: FormatAlignLeft,
    FormatAlignRight: FormatAlignRight,
    FormatBold: FormatBold,
    FormatColorText: FormatColorText,
    FormatItalic: FormatItalic,
    FormatListNumbered: FormatListNumbered,
    FormatStrikethrough: FormatStrikethrough,
    FormatUnderline: FormatUnderlined,
    Forms: FormatAlignJustifyOutlined,
    Grid: Grid3x3Outlined,
    GridGrouping: AccountTree,
    Help: HelpOutlineOutlined,
    HideImage: HideImage,
    Home: HomeOutlined,
    Image: Image,
    InfoIcon: Info,
    KeyboardArrowDown: KeyboardArrowDown,
    Lab: ScienceOutlined,
    Language: LanguageOutlined,
    Launch: Launch,
    Leads: CenterFocusStrongOutlined,
    LightMode: LightModeOutlined,
    ListViewGallery: SpaceDashboardOutlined,
    ListViewList: FormatListBulletedOutlined,
    ListViewTable: ViewColumnOutlined,
    Location: LocationOn,
    LocationOutlined: LocationOnOutlined,
    Lock: LockOutlined,
    Logout: LogoutOutlined,
    Loop: LoopOutlined,
    ManageAccounts: ManageAccountsOutlined,
    Marketplace: StoreOutlined,
    Menu: MenuOutlined,
    Money: AttachMoney,
    MongoReportComponent: DashboardOutlined,
    MoreVert: MoreVertOutlined,
    MovieCreation: MovieCreation,
    NoteAdd: NoteAdd,
    Notes: NotesOutlined,
    NotesOutlined: NoteAltOutlined,
    OpenInNew: OpenInNewOutlined,
    OpenWith: OpenWith,
    Order: Order,
    Organization: BusinessOutlined,
    Password: Password,
    PdfFile: PictureAsPdfOutlined,
    People: PeopleOutlineOutlined,
    PersonOutline: PersonOutline,
    Phone: LocalPhone,
    PivotTableChart: PivotTableChart,
    Place: PublicOutlined,
    PlaceIcon: Place,
    Presets: SettingsApplicationsOutlined,
    Preview: Visibility,
    Product: ShoppingBasketOutlined,
    Production: Production,
    Products: ShoppingBasketOutlined,
    Profile: ManageAccountsOutlined,
    Prop: AutoFixHigh,
    Quote: Quote,
    Rate: SellOutlined,
    Redo: Redo,
    Refresh: Refresh,
    Report: BarChart,
    RequestAvailability: RequestAvailability,
    RequestSent: RequestSent,
    ResponseRecieved: ResponseReceived,
    TestReport: Assessment,
    ResourceFields: WidgetsOutlined,
    RichText: FormatColorText,
    Rsvp: RsvpOutlined,
    Save: SaveOutlined,
    Schedule: EventAvailableOutlined,
    SchedulerPreview: ViewTimeline,
    Science: ScienceOutlined,
    Search: SearchOutlined,
    Security: SecurityOutlined,
    Services: Service,
    Success: Success,
    Settings: AdminPanelSettingsOutlined,
    Share: ShareOutlined,
    Shortlist: FormatListBulleted,
    Sort: SortOutlined,
    ShowrunnrMark: ShowrunnrLogoMark,
    SquareIcon: Square,
    Star: Star,
    StarOutline: StarOutline,
    Store: StoreOutlined,
    Summary: Summarize,
    Templates: NewspaperOutlined,
    Terminal: TerminalOutlined,
    TheaterComedy: TheaterComedy,
    Tune: TuneOutlined,
    Undo: Undo,
    UnfoldLess: UnfoldLessOutlined,
    UnfoldMore: UnfoldMoreOutlined,
    Vehicle: DirectionsCar,
    Visibility: Visibility,
    VisibilityOff: VisibilityOff,
    WardrobeIcon: Checkroom,
    Workshop: HomeRepairService,
    Workspaces: Workspaces,
    ZipFolder: FolderZipOutlined,
    Info: InfoOutlined,
    BrokenImage: BrokenImageOutlined,
    DigitalAssets: DigitalAssets,
    CreativeWork: CreativeWork,
    LinkedIn: LinkedIn,
    IMDb: IMDb,
    GoogleLogo: GoogleLogo,
    MicrosoftLogo: MicrosoftLogo,
    ImportFile: ImportFile
};

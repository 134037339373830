import React, { useState } from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { InquiryInfo, Opportunity } from 'sr-types/lib/opportunity/v1/graphql';
import { V } from '../common/Layout';
import { InquiryPreviewInfo } from './form/InvitePreview';
import I18n from '../common/i18n/I18n';

import OpportunityResponseFormItems from '../supply/opportunity/details/OpportunityResponseFormItems';

type InquiryPreviewType = {
    data: InquiryInfo;
};

export default ({ data }: InquiryPreviewType) => {
    const { inquiry, personalizedMessage } = data || {};

    const [requestNoteLabel, setRequestNoteLabel] = useState('');

    return inquiry ? (
        <V sx={{ height: '100%', px: 4, py: 1 }}>
            <V sx={{ gap: 2.5, justifyContent: 'start' }}>
                <Typography>
                    <I18n token="form.inquiryResponse.hi" />{' '}
                    {data.inquiry.name.substring(0, data.inquiry.name.indexOf(' '))},
                </Typography>

                <InquiryPreviewInfo
                    productionName={inquiry.production.name}
                    orgName={inquiry.organization.name}
                    role={inquiry.productionRequirements?.[0]?.role}
                    summary={inquiry.summary.about}
                    dates={inquiry.opportunityPeriod}
                    locations={inquiry.productionRequirements?.[0]?.locations}
                    personalizedMessage={personalizedMessage}
                    productionType={inquiry.production?.type}
                />

                <OpportunityResponseFormItems
                    inquiryData={inquiry as Opportunity}
                    inquiryResponseStatus={inquiry.status}
                    requestNoteLabel={requestNoteLabel}
                    setRequestNoteLabel={setRequestNoteLabel}
                    inviteeName={inquiry.contacts?.[0]?.name?.firstName}
                />
            </V>
        </V>
    ) : (
        <></>
    );
};

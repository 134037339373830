import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomAuth from '../common/auth/CustomAuth';
import { UserContext } from '../common/auth/UserContext';
import { constants } from '../common/constants';
import { FormProvider } from '../common/form/FormContext';
import Nav from '../common/nav/Nav';
import useHistory from '../common/utils/useHistory';
import { ValidationRulesType } from '../production/helpers/productionUtils';
import { clientWithoutAuth, inquiry } from '../supply/opportunity/opportunity';
import InquirySuccess from './InquirySuccess';
import { setToken as setLocalStorageToken } from '../common/auth/api';
import { inquiryResponseStatuses } from '../supply/opportunity/opportunityInquiry';
import Validator from '../common/form/Validator';
import InquiryResponseFormModal from './InquiryResponseFormModal';

export default () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { searchParams } = useHistory();

    const inquiryId = searchParams.get('inquiryId');
    const username = searchParams.get('username');

    const [values, setValues] = useState({
        status: undefined,
        requestNote: undefined
    });
    const [isPreview, setIsPreview] = useState(true);
    const [thankYouVisible, setThankYouVisible] = useState(false);
    const [token, setToken] = useState('');

    const validationRules: ValidationRulesType = useMemo(() => {
        return {
            status: [
                {
                    isValid: (prop, state, isModified, resolveI18nToken) => {
                        const value = _.get(state, prop);
                        return !!value && value?.id !== inquiryResponseStatuses.requestSent
                            ? true
                            : resolveI18nToken('validation.value.required');
                    },
                    isRequired: true
                }
            ]
        };
    }, [values]);

    const [dynamicValidationRules, setDynamicValidationRules] = useState({ ...validationRules });

    useEffect(() => {
        if (values?.status?.id === inquiryResponseStatuses.requestInfo) {
            setDynamicValidationRules({ requestNote: [Validator.RULES.isRequired] });
        } else {
            setDynamicValidationRules({ ...validationRules });
        }
    }, [values?.status?.id]);

    const [getInquiry, { data, loading }] = useLazyQuery(inquiry, {
        client: clientWithoutAuth,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (token) {
            getInquiry({
                variables: { id: inquiryId, organizationId: activeOrganizationAccount },
                context: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            });
        }
        setLocalStorageToken(token);
    }, [token]);

    return (
        <>
            <Nav />
            {token ? (
                <FormProvider state={values} setState={setValues} validationRules={dynamicValidationRules}>
                    {isPreview ? (
                        <InquiryResponseFormModal
                            data={data}
                            dataLoading={loading}
                            token={token}
                            thankYouVisible={thankYouVisible}
                            setIsPreview={setIsPreview}
                            setThankYouVisible={setThankYouVisible}
                        />
                    ) : (
                        <></>
                    )}
                    {thankYouVisible ? (
                        <InquirySuccess name={data?.inquiry?.inquiry?.contacts?.[0]?.name?.firstName} />
                    ) : (
                        <></>
                    )}
                </FormProvider>
            ) : (
                <>
                    <CustomAuth
                        username={username}
                        setBearer={(token) => {
                            setToken(token);
                        }}
                    />
                </>
            )}
        </>
    );
};
